body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: hsl(48.3, 100%, 92.9%);
  color: hsl(209, 61%, 16%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  letter-spacing:  0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
  width: 324px;
  display: contents;
}

h5 {
  margin-top: auto;
  line-height: 17px;
}

p {
  margin-bottom: 1.25rem;
  color: hsl(210, 22%, 49%);
}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1400px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
.menu {
  padding: 5rem 0;
}
.title {
  text-align: center;
  margin-bottom: 1rem;
}

.underline {
  width: 6rem;
  height: 0.25rem;
  background: #c59d5f;
  margin-left: auto;
  margin-right: auto;
}

.btn-container {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
}

.filter-btn {
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color:  hsl(360, 71%, 66%);
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0.25rem;
}

.active {
  border-bottom: 2px solid;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1300px;
  display: grid;
  gap: 3rem 2rem;
  justify-items: stretch;
}

.section-bottom {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  justify-items: stretch;
}

.drinks-item {
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}

.menu-item {
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
}

.photo {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border: 0.25rem solid hsl(360, 71%, 66%);
  border-radius: 4px;
  display: block;
  
}

.logo {
  object-fit: cover;
  height: auto;
  width: 200px;
  border-radius: 0.25;  
}

.item-info header {
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.drinks-info header {
  height: 15px;
}

.item-text-price-container {
  display: flex;
  flex-direction: row;
}

.item-text {
  font-size: small;
  margin: 2px;
  align-self: flex-start;
  padding-top: 4px;
}

.item-price {
  font-size: small;
  margin: 10px;
  display: flex;
  align-items: flex-start;
  width: 50px;
}

.drinks-price {
  font-size: small;
  margin: 10px;
  display: flex;
  align-items: flex-start;
  width: 50px;
}


@media screen and (min-width: 768px) {
  .menu-item {
    grid-template-columns: 200px 1fr;
    gap: 0 1.25rem;
    max-width: 40rem;
  }
  .photo {
    height: 175px;
  }
}
@media screen and (min-width: 1200px) {
  .section-center {
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }
  .photo {
    height: 150px;
  }
}